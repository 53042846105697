/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Peddana&display=swap");
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.min.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

:root {
  --primary-color: rgba(218, 165, 32, 1);
  --secondary-color: rgba(255, 255, 255, 1);
  --background-color: rgba(21, 34, 56, 1);
  --button-color: rgba(0, 0, 0, 1);
  --dark-background-color: rgb(0, 0, 0);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: var(--secondary-color);
}
